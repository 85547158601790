@import '~bulma/sass/utilities/mixins';

.main {
    max-width: 700px !important;
}

.column.is-vcentered {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.daily-date {
    font-size: 1.5em;
    font-weight: 700;
}

.daily-day {
    font-size: 0.8em;
    font-weight: 700;
}

.daily-month {
    font-size: 0.8em;
}

.trx-row.income .trx-amount {
    color: #039be5 !important;
}

.trx-row.outcome .trx-amount {
    color: #e51c23 !important;
}

.calendar .columns {
    height: 100px;
    border-left: 1px black solid;
    border-right: 1px black solid;
}

.calendar .calendar-date {
    border: 1px black solid;
}

button.add-button {
    position: fixed;
    border-radius: 2.3rem;
    font-size: 2rem;
    font-weight: 700;
    width: 3.5rem;
    height: 3.5rem;
    padding: 0 0 0.4rem;
    z-index: 1;

    right: calc(25% + 1.5rem);
    bottom: 2rem;

    @include mobile {
        right: 1.5rem;
    }
}
